import { Component } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ExecutionLink';
  authCheck = { complete: false };
  idmLinkUrl = environment.webUrl.idm;
  dev = !environment.production;
}
