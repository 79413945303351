import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '../form.service';
import { Form } from '../form.model';
import { FormioModel } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-form',
  templateUrl: './form-builder-add-or-edit.component.html',
  styleUrls: ['./form-builder-add-or-edit.component.scss']
})
export class FormBuilderAddOrEditComponent implements OnInit {
  activeForm: FormioModel;
  formKey: string;

  @ViewChild('json', { static: true }) jsonElement?: ElementRef;
  @ViewChild('code', { static: true }) codeElement?: ElementRef;
  public form: Form;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService
  ) {
    this.form = new Form();
    this.form.json = {
      components: []
    };
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('key')) {
        this.formKey = params.get('key') ?? null;
        if (this.formKey) {
          this.formService.getByKey(this.formKey).then(response => {
            this.form = response;
            this.form.json = JSON.parse(String(response.json))
          });
        }
      }
    });
  }

  onChange(event) {
    this.jsonElement.nativeElement.innerHTML = '';
    this.jsonElement.nativeElement.appendChild(
      document.createTextNode(JSON.stringify(event.form, null, 4))
    );
    this.activeForm = event.form;
  }

  dirty() {
    this.activeForm = this.form.json;
  }

  navigateBack() {
    this.router.navigate(['form-builder']);
  }

  onSave() {
    if (this.form.id && this.formKey === this.form.key) {
      this.formService
        .update({
          ...this.form,
          json: this.activeForm
        })
        .then(() => this.navigateBack());
    } else {
      this.formService
        .create({
          key: this.formKey,
          json: this.activeForm
        })
        .then(() => this.navigateBack());
    }
  }
}
