import { Component } from '@angular/core';
import { WorkflowProcess } from '@ov-suite/adminlink-models';
import { WorkflowProcessService } from '@ov-suite/services-angular';
import { HierarchyTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-workflow-process-list',
  templateUrl: './workflow-process-list.component.html',
  styleUrls: ['./workflow-process-list.component.scss']
})
export class WorkflowProcessListComponent {
  // Class - Required
  formClass = WorkflowProcess;
  tabs: HierarchyTab[] = [
    { title: 'Workflow Processes', iconClass: '', path: '/workflow-processes' }
  ];

  constructor(public workflowProcessService: WorkflowProcessService) {}
}
