import {
  getTaggedClass,
  OVEntity,
  OVField,
  OVSearchable,
  OVTable
} from '@ov-suite/ov-metadata';
import { IRate } from '../interfaces/rate.interface';
import { ShippingRoute } from './ceramic-shipping-route.model';
import { Rate } from './rate.model';
import { CeramicTransporter } from './ceramic-transporter.model';
import { Plant } from './plant.model';
import { CostType } from './cost-type.model';
import { PickupPenaltyBand } from './pickup-penalty-band.model';
import { DropPenaltyBand } from './drop-penalty-band.model';
import { VehicleType } from "../adminlink";
import { WeightBand } from './weight-band.model';
import moment from 'moment';
import { RateCostType } from './rate-cost-type.model';

@OVEntity('RateChange', 'ceramic-portal')
@OVTable<RateChange>([
  {
    key: 'id',
    title: 'Rate ID',
    type: 'string',
    hideColumnKey: 'id'
  },
  {
    title: 'Rate Name',
    type: 'other',
    action: data => data?.name,
    keys: ['name']
  },
  {
    title: 'Lane',
    type: 'other',
    action: data => data?.route?.name,
    keys: ['route.name']
  },
  {
    title: 'Transporter',
    type: 'other',
    action: data => data?.transporter?.transporter.name,
    keys: ['transporter.transporter.name']
  },
  {
    title: 'Rate',
    type: 'other',
    action: data =>
      `<strike>${data?.currentRate?.rate}</strike> &#x21AA; ${data.rate}`,
    keys: ['currentRate.rate', 'rate']
  },
  {
    key: 'rateRange',
    title: 'Weight Band',
    type: 'string'
  },
  {
    title: 'Pickup Penalty',
    type: 'other',
    action: data => `
      <strike>
        ${data?.currentRate?.pickupPenaltyBand?.pickupPenalty}
     </strike> &#x21AA; ${data?.pickupPenaltyBand?.pickupPenalty}`,
    keys: [
      'currentRate.pickupPenaltyBand.pickupPenalty',
      'pickupPenaltyBand.pickupPenalty'
    ]
  },
  {
    title: 'Pickup Penalty Range',
    type: 'other',
    action: data => `
        ${data?.currentRate?.pickupPenaltyBand?.ppFrom} -
        ${data?.currentRate?.pickupPenaltyBand?.ppTo}`,
    keys: [
      'currentRate.pickupPenaltyBand.ppFrom',
      'currentRate.pickupPenaltyBand.ppTo'
    ]
  },
  {
    title: 'Drop Penalty',
    type: 'other',
    action: data => `
      <strike>
        ${data?.currentRate?.dropPenaltyBand?.dropPenalty}
     </strike> &#x21AA; ${data?.dropPenaltyBand?.dropPenalty}`,
    keys: [
      'currentRate.dropPenaltyBand.dropPenalty',
      'dropPenaltyBand.dropPenalty'
    ]
  },
  {
    title: 'Drop Penalty Range',
    type: 'other',
    action: data => `
        ${data?.currentRate?.dropPenaltyBand?.dpFrom} -
        ${data?.currentRate?.dropPenaltyBand?.dpTo}`,
    keys: [
      'currentRate.dropPenaltyBand.dpFrom',
      'currentRate.dropPenaltyBand.dpTo'
    ]
  },
  {
    title: 'Valid From',
    type: 'other',
    keys: ['validFrom'],
    action: col => moment(col.validFrom).format('YYYY-MM-DD'),
  },
  {
    title: 'Status',
    type: 'other',
    hideColumnKey: 'status',
    action: data => `<div class="badge ${
      data?.changeStatus === 'APPROVED' ? 'badge-success' : 'badge-info'
    }"> ${data?.changeStatus}
    </div>`,
    keys: ['changeStatus']
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actions',
    buttons: [
      {
        classes: 'btn btn-link fa fa-check text-primary',
        action: (item, { ngbModal }) => {
          const modalRef = ngbModal.open(
            getTaggedClass('rate-approval-popup', RateChange)
          );
          modalRef.componentInstance.name = 'Approve Rate Changes';
          modalRef.componentInstance.rateChanges = [item];
        }
      },
      {
        classes: 'btn btn-link fa fa-times text-primary',
        action: (item, { ngbModal }) => {
          const modalRef = ngbModal.open(
            getTaggedClass('rate-decline-popup', RateChange)
          );
          modalRef.componentInstance.name = 'Reject Rate Change';
          modalRef.componentInstance.rateChanges = [item];
        }
      }
    ],
    keys: []
  }
])
export class RateChange implements IRate {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Change Status',
    placeholder: '-'
  })
  changeStatus: string; // Enum

  @OVField({
    type: 'string',
    title: 'Reason',
    placeholder: '-'
  })
  reason: string;

  @OVField({
    type: () => Rate,
    title: 'Current Rate',
    keys: [
      'id',
      'rate',
      'pickupPenaltyBand.pickupPenalty',
      'pickupPenaltyBand.ppFrom',
      'pickupPenaltyBand.ppTo',
      'dropPenaltyBand.dropPenalty',
      'dropPenaltyBand.dpFrom',
      'dropPenaltyBand.dpTo'
    ]
  })
  currentRate: Rate;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Rate Name',
    placeholder: '-',
    required: false
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Rate',
    placeholder: '-'
  })
  rate: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Distance',
    placeholder: '-'
  })
  distance: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Weight Band',
    placeholder: '-'
  })
  rateRange: string;

  @OVSearchable()
  @OVField({
    type: () => CeramicTransporter,
    title: 'Transporter',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['transporter.name']
  })
  transporter: CeramicTransporter;

  @OVSearchable()
  @OVField({
    type: () => ShippingRoute,
    title: 'Route',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    keys: ['id', 'name']
  })
  route: ShippingRoute;

  @OVSearchable()
  @OVField({
    type: () => Plant,
    title: 'Plant',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
    // keys: ['id', 'name']
  })
  plant: Plant;

  @OVSearchable()
  @OVField({
    type: () => VehicleType,
    title: 'Vehicle Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    keys: ['id', 'vehicleType']
  })
  vehicleType: VehicleType;

  @OVSearchable()
  @OVField({
    type: () => CostType,
    title: 'Cost Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    keys: ['id', 'costType']
  })
  costType: CostType;

  @OVSearchable()
  @OVField({
    type: () => RateCostType,
    title: 'Cost Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['costType'],
    required: true
  })
  rateCostType: RateCostType;

  @OVSearchable()
  @OVField({
    type: () => PickupPenaltyBand,
    title: 'Pickup Penalty Band',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    keys: ['id', 'ppApplication', 'ppFrom', 'ppTo', 'pickupPenalty']
  })
  pickupPenaltyBand: PickupPenaltyBand;

  @OVSearchable()
  @OVField({
    type: () => DropPenaltyBand,
    title: 'Drop Penalty',
    placeholder: '-',
    selectionType: 'simple',
    flat: true
  })
  dropPenaltyBand: DropPenaltyBand;

  @OVSearchable()
  @OVField({
    type: () => WeightBand,
    title: 'Weight Band',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['weightBand'],
    required: true
  })
  weightBand: WeightBand;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Valid From',
    placeholder: '-',
    required: true
  })
  validFrom: Date;

  @OVSearchable()
  @OVField({
    type: 'date',
    title: 'Valid To',
    placeholder: '-',
    required: true
  })
  validTo: Date;

  @OVField({
    type: 'date',
    title: 'Created At',
    placeholder: '-',
    required: true
  })
  createdAt: Date;

  @OVField({
    type: 'date',
    title: 'Updated At',
    placeholder: '-',
    required: true
  })
  updatedAt: Date;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Created By',
    placeholder: '-'
  })
  createdBy: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Updated By',
    placeholder: '-'
  })
  updatedBy: string;
}
