import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { MobileSod } from '@ov-suite/adminlink-models';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: MobileSod,
      api: 'shared',
      createTitle: 'Mobile Start of Day Configuration',
      route: ['mobile-sod'],
      tableTitle: 'Mobile Start of Day Configurations'
    })
  ]
})
export class MobileSodModule {}
