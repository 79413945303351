import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Stop } from '@ov-suite/adminlink-models';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Stop,
      api: 'executionlink',
      createTitle: 'Stop',
      route: ['stop'],
      tableTitle: 'Stops'
    })
  ]
})
export class StopModule {}
