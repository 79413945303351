import gql from 'graphql-tag';
import { User } from '@ov-suite/adminlink-models';

export function listUsersQuery(keys: (keyof User)[]) {
  return gql(`query ListUsers($orderDirection: String, $orderColumn: String, $filter: String, $limit: Int, $offset: Int) {
    listUsersWithCount(orderDirection: $orderDirection, orderColumn: $orderColumn, filter: $filter, limit: $limit, offset: $offset) {
      data {
        ${keys.join(`\n`)}
      }
      totalCount
     }
   }`);
}
