<div class="wrapper">
  <div class="sidebar" data-color="brown" data-active-color="danger">
    <ov-suite-sidebar
      [projectName]="'EXECUTION LINK'"
      [routes]="routes"
    ></ov-suite-sidebar>
  </div>
  <div class="main-panel">
    <ov-suite-navbar [routes]="routes"></ov-suite-navbar>
    <router-outlet></router-outlet>
  </div>
</div>

<!--<div class="wrapper">-->
<!--  <div class="sidebar" data-color="brown" data-active-color="danger">-->
<!--    <ov-suite-sidebar [routes]="routes"></ov-suite-sidebar>-->
<!--  </div>-->
<!--  <div class="main-panel">-->
<!--    <ov-suite-navbar [routes]="routes"></ov-suite-navbar>-->
<!--    <router-outlet></router-outlet>-->
<!--  </div>-->
<!--</div>-->
