import gql from 'graphql-tag';
import { Task } from '@ov-suite/helpers-shared';

export function getTaskQuery(keys: (keyof Task)[]) {
  return gql(`query GetTask($taskId: String!) {
    getTask(taskId: $taskId) {
      ${keys.join(`\n`)}
      variables {
        name
        value
      }
     }
   }`);
}

export function listTasksQuery(keys: (keyof Task)[], includeVariables = false) {
  return gql(`query ListTasks($query: ListTasksInput!) {
    listTasks(query: $query) {
      data {
        ${keys.join(`\n`)}
        variables {
          name
          value
        }
      }
      total
      start
      sort
      order
      size
     }
   }`);
}

export function queryTasksQuery(keys: (keyof Task)[]) {
  return gql(`query QueryTasks($query: QueryTasksInput!) {
    queryTasks(query: $query) {
      data {
        ${keys.join(`\n`)}
        variables {
          name
          value
        }
      }
      total
      start
      sort
      order
      size
     }
   }`);
}

export function listTasksDuplicatedQuery(
  keys: (keyof Task)[],
  duplicates = ['listTasks'],
  includeVariables = false
) {
  // console.log('List Tasks Duplicates');
  return gql(`query ListTasks(${duplicates.map(
    alias => `$${alias}: ListTasksInput!`
  )}) {
    ${duplicates.map(
      alias => `
      ${alias}: listTasks(query: $${alias}) {
          data {
            ${keys.join(`\n`)}
            variables {
              name
              value
            }
          }
          total
          start
          sort
          order
          size
         }
    `
    )}
   }`);
}

export function taskActionMutation(keys: ('ok' | 'tasks')[] = ['ok']) {
  return gql(`mutation TaskAction($action:TaskActionsInput!, $taskId:String!) {
    taskAction(action: $action, taskId: $taskId) {
      ${keys.includes('ok') ? 'ok' : ''}
      ${
        keys.includes('tasks')
          ? 'tasks { id assignee, id, form, formKey, category, name, priority, variables { name value } }'
          : ''
      }
     }
   }`);
}
