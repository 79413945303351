import {
  OVField,
  OVSearchable,
  OVEntity,
  OVForm,
  OVTable
} from '@ov-suite/ov-metadata';

@OVEntity('RateCostType', 'ceramic-portal')
@OVForm([['id', 'name']])
@OVTable<RateCostType>([
  {
    key: 'id',
    title: 'Cost Type ID',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string'
  }
])
export class RateCostType {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

}
