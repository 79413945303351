import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from '@ov-suite/authguard-angular';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'form-builder',
        loadChildren: () => import('@ov-suite/modules-angular').then(m => m.FormBuilderModule)
      },
      {
        path: 'mobile-list-config',
        loadChildren: () => import('@ov-suite/modules-angular').then(m => m.MobileListConfigModule)
      },
      {
        path: 'mobile-start-config',
        loadChildren: () => import('@ov-suite/modules-angular').then(m => m.MobileStartConfigModule)
      },
      {
        path: 'mobile-sod',
        loadChildren: () => import('@ov-suite/modules-angular').then(m => m.MobileSodModule)
      },
      {
        path: 'mobile-eod',
        loadChildren: () => import('@ov-suite/modules-angular').then(m => m.MobileEodModule)
      },
      {
        path: 'workflow-processes',
        loadChildren: () =>
          import('./modules/workflow-process/workflow-process.module').then(
            m => m.WorkflowProcessModule
          )
      },
      {
        path: 'stop',
        loadChildren: () =>
          import('./modules/stop/stop.module').then(m => m.StopModule)
      },
      {
        path: 'trip',
        loadChildren: () =>
          import('./modules/trip/trip.module').then(m => m.TripModule)
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
