import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Trip } from '@ov-suite/adminlink-models';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Trip,
      api: 'executionlink',
      createTitle: 'trip',
      route: ['trip'],
      tableTitle: 'Trips',
      hideAdd: false,
      hideImport: true
    })
  ]
})
export class TripModule {}
