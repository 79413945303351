import {
  CompiledFieldData, FieldParamsQuery,
  getTaggedClass, getTaggedMethod,
  OVEntity,
  OVField,
  OVForm,
  OVSearchable,
  OVSidebar,
  OVTable
} from '@ov-suite/ov-metadata';
import { IRate } from '../interfaces/rate.interface';
import { Plant } from './plant.model';
import { CostType } from './cost-type.model';
import { PickupPenaltyBand } from './pickup-penalty-band.model';
import { DropPenaltyBand } from './drop-penalty-band.model';
import { ShippingRoute } from './ceramic-shipping-route.model';
import { CeramicTransporter } from './ceramic-transporter.model';
import { VehicleType } from '../adminlink/vehicle-type.model';
import { WeightBand } from './weight-band.model';
import moment from 'moment';
import { RateCostType } from './rate-cost-type.model';

const validateRate = async (
  data: CompiledFieldData<Date>,
  dataQuery: FieldParamsQuery
): Promise<[boolean, string]> => {
  const validator = getTaggedMethod('rate-validator', Rate);
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
  if (data.value && !dataQuery('id')) {
    const response = await validator({
      name: dataQuery('name'),
      validFrom: moment(data.value).format(dateFormat) + 'Z',
      validTo: moment(dataQuery('validTo')).format(dateFormat) + 'Z'
    });
    if (response.status === 500) {
      return [false, 'There is an overlapping rate.'];
    }
  }
  return [true, ''];
};

@OVEntity('RateStatus', 'ceramic-portal')
export class RateStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Rate', 'ceramic-portal')
@OVForm([
  ['name', 'rate', 'distance'],
  ['plant', 'route'],
  ['weightBand', 'pickupPenaltyBand', 'dropPenaltyBand'],
  ['rateCostType', 'vehicleType'],
  ['validFrom', 'validTo'],
  ['transporter', '']
])
@OVSidebar([['status'], ['id']])
@OVTable<Rate>([
  {
    key: 'id',
    title: 'Rate ID',
    type: 'string'
  },
  {
    key: 'name',
    hideColumnKey: 'rateName',
    title: 'Rate Name',
    type: 'string'
  },
  {
    title: 'Plant Name',
    type: 'other',
    action: data => data.plant?.name,
    hideColumnKey: 'plantName',
    keys: ['plant.name']
  },
  {
    title: 'Route',
    type: 'other',
    action: data => data.route?.routeCode,
    hideColumnKey: 'routeCode',
    keys: ['route.routeCode']
  },
  {
    title: 'Route Name',
    type: 'other',
    action: data => data.route?.name,
    hideColumnKey: 'routeName',
    keys: ['route.name']
  },
  {
    title: 'Route Description',
    type: 'other',
    action: data => data.route?.description,
    hideColumnKey: 'routeDescription',
    keys: ['route.description']
  },
  {
    title: 'Transporter',
    type: 'other',
    action: data => data.transporter?.transporter?.name,
    hideColumnKey: 'transporterName',
    keys: ['transporter.transporter.name']
  },
  {
    title: 'Vehicle Capacity',
    type: 'other',
    action: data => data.vehicleType?.name,
    hideColumnKey: 'vehicleType',
    keys: ['vehicleType.name']
  },
  {
    key: 'distance',
    title: 'Distance',
    type: 'string'
  },
  {
    key: 'rate',
    title: 'Rate',
    type: 'string'
  },
  {
    keys: ['weightBand'],
    title: 'Weight Band',
    type: 'other',
    action: data => data?.weightBand?.weightBand
  },
  {
    title: 'Pickup Penalty',
    type: 'other',
    action: data => `${data.pickupPenaltyBand?.pickupPenalty}`,
    keys: ['pickupPenaltyBand.pickupPenalty']
  },
  {
    title: 'Drop Penalty',
    type: 'other',
    action: data => `${data.dropPenaltyBand?.dropPenalty}`,
    keys: ['dropPenaltyBand.dropPenalty']
  },
  {
    title: 'Valid From',
    type: 'other',
    keys: ['validFrom'],
    action: col => moment(col.validFrom).format('YYYY-MM-DD'),
  },
  {
    title: 'Valid To',
    type: 'other',
    keys: ['validTo'],
    action: col => moment(col.validTo).format('YYYY-MM-DD'),
  },
  {
    key: 'createdBy',
    hideColumnKey: 'createdBy',
    title: 'Created By',
    type: 'string'
  },
  {
    key: 'updatedBy',
    hideColumnKey: 'updatedBy',
    title: 'Updated By',
    type: 'string'
  },
  {
    title: 'View',
    hideColumnKey: 'view',
    type: 'buttons',
    buttons: [
      {
        classes: 'btn-link fa fa-newspaper-o text-primary',
        action: (item, services) => {
          const modalRef = services.ngbModal.open(
            getTaggedClass('rate-detail', Rate),
            { size: 'xl', windowClass: 'rate-detail' }
          );
          modalRef.componentInstance.rate = item;
        }
      }
    ],
    keys: ['id']
  }
])
export class Rate implements IRate {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Rate Name',
    placeholder: '-',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Rate',
    placeholder: '-',
    required: true
  })
  rate: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Distance',
    placeholder: '-',
    required: false
  })
  distance: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Rate Band',
    placeholder: '-',
    required: true
  })
  rateRange: string;

  @OVSearchable()
  @OVField({
    type: () => CeramicTransporter,
    title: 'Transporter',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['transporter.name'],
    keys: ['id', 'transporter.name'],
    required: true
  })
  transporter: CeramicTransporter;

  @OVSearchable()
  @OVField({
    type: () => ShippingRoute,
    title: 'Route',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['routeCode', 'name'],
    required: true,
  })
  route: ShippingRoute;

  @OVSearchable()
  @OVField({
    type: () => Plant,
    title: 'Plant',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    required: true,
    keys: ['id', 'name'],
  })
  plant: Plant;

  @OVSearchable()
  @OVField({
    type: () => VehicleType,
    title: 'Vehicle Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['name'],
    required: true,
    keys: ['id', 'name'],
  })
  vehicleType: VehicleType;

  @OVSearchable()
  @OVField({
    type: () => CostType,
    title: 'Cost Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['costType'],
    required: true
  })
  costType: CostType;

  @OVSearchable()
  @OVField({
    type: () => RateCostType,
    title: 'Cost Type',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['name'],
    required: true
  })
  rateCostType: RateCostType;

  @OVSearchable()
  @OVField({
    type: () => PickupPenaltyBand,
    title: 'Pickup Penalty Band',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['ppBand'],
    required: true
  })
  pickupPenaltyBand: PickupPenaltyBand;

  @OVSearchable()
  @OVField({
    type: () => DropPenaltyBand,
    title: 'Drop Penalty Band',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['dpBand'],
    required: true
  })
  dropPenaltyBand: DropPenaltyBand;

  @OVSearchable()
  @OVField({
    type: () => WeightBand,
    title: 'Weight Band',
    placeholder: '-',
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['weightBand'],
    required: true
  })
  weightBand: WeightBand;

  @OVSearchable()
  @OVField({
    type: 'date-time',
    title: 'Valid From',
    placeholder: '-',
    required: true,
    validator: validateRate
  })
  validFrom: Date;

  @OVSearchable()
  @OVField({
    type: 'date-time',
    title: 'Valid To',
    placeholder: '-',
    required: true
  })
  validTo: Date;

  @OVField({
    type: () => RateStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: RateStatus;

  @OVField({
    type: 'date',
    title: 'Created At',
    placeholder: '-',
    required: true
  })
  createdAt: Date;

  @OVField({
    type: 'date',
    title: 'Updated At',
    placeholder: '-',
    required: true
  })
  updatedAt: Date;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Created By',
    placeholder: '-'
  })
  createdBy: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Updated By',
    placeholder: '-'
  })
  updatedBy: string;
}
