import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ov-suite-process-task-list',
  templateUrl: './process-task-list.component.html',
  styleUrls: ['./process-task-list.component.css']
})
export class ProcessTaskListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
