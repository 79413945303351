import { AutoService } from '@ov-suite/services';
import {
  AppService,
  UserTypeService,
  WorkflowProcessService,
} from '@ov-suite/services-angular';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { OVEntity, OVField } from '@ov-suite/ov-metadata';

@OVEntity('GenericStatus', 'adminlink')
class GenericStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@Injectable()
export class GenericStatusService extends AutoService<GenericStatus> {
  constructor(private apollo: Apollo) {
    super(apollo, GenericStatus, '', '', '');
  }
}

export class MockStatusService extends GenericStatus {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<GenericStatus>> {
  //   return { data: [], totalCount: 0 };
  // }
}
export class MockAppService extends AppService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<App>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockUserTypeService extends UserTypeService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<UserType>> {
  //   return { data: [], totalCount: 0 };
  // }
}

export class MockProcessInstanceService extends WorkflowProcessService {
  // async list(
  //   filter: string,
  //   limit: number = 1,
  //   offset: number = 0,
  //   orderColumn,
  //   orderDirection
  // ): Promise<PageReturn<WorkflowProcess>> {
  //   return { data: [], totalCount: 0 };
  // }
}
