import process from "process";

export const apiEnvironment = {
  port: {
    idm: process.env.IDM_API_PORT ? Number(process.env.IDM_API_PORT) : 3332,
    admin: process.env.ADMIN_API_PORT ? Number(process.env.ADMIN_API_PORT) : 3333,
    warehouse: process.env.WAREHOUSE_API_PORT ? Number(process.env.WAREHOUSE_API_PORT) : 3334,
    yard: process.env.YARD_API_PORT ? Number(process.env.YARD_API_PORT) : 3335,
    execution: process.env.EXECUTION_API_PORT ? Number(process.env.EXECUTION_API_PORT) : 3336,
    ceramicPortal: process.env.CERAMIC_PORTAL_API_PORT ? Number(process.env.CERAMIC_PORTAL_API_PORT) : 3337,
  },
  microServicePort: {
    admin: process.env.ADMIN_MICRO_PORT ? Number(process.env.ADMIN_MICRO_PORT) : 2333,
    idm: process.env.IDM_MICRO_PORT ? Number(process.env.IDM_MICRO_PORT) : 2332,
    warehouse: process.env.WAREHOUSE_MICRO_PORT ? Number(process.env.WAREHOUSE_MICRO_PORT) : 2334,
    yard: process.env.YARD_MICRO_PORT ? Number(process.env.YARD_MICRO_PORT) : 2335,
    execution: process.env.EXECUTION_MICRO_PORT ? Number(process.env.EXECUTION_MICRO_PORT) : 2336,
    ceramicPortal: process.env.CERAMIC_PORTAL_MICRO_PORT ? Number(process.env.CERAMIC_PORTAL_MICRO_PORT) : 2337,
  },
  database: {
    host: process.env.DATABASE_HOST ?? 'localhost',
    username: process.env.DATABASE_USERNAME ?? 'postgres',
    password: process.env.DATABASE_PASSWORD ?? 'postgres',
    idmLinkSchema: process.env.DATABASE_IDM ?? 'idmlink',
    adminLinkSchema: process.env.DATABASE_ADMIN ?? 'adminlink',
    warehouseLinkSchema: process.env.DATABASE_WAREHOUSE ?? 'warehouselink',
    yardLinkSchema: process.env.DATABASE_YARD ?? 'yardlink',
    executionLinkSchema: process.env.DATABASE_EXECUTION ?? 'executionlink',
    ceramicPortalSchema: process.env.DATABASE_CERAMIC ?? 'ceramicportal'
  },
  flowable: {
    warehouse: {
      username: process.env.FLOWABLE_WAREHOUSE_USERNAME ?? 'devops-warehouse',
      password: process.env.FLOWABLE_WAREHOUSE_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_WAREHOUSE_URL ?? 'https://dev.flowable.ovsuite.com/flowable-task',
    },
    yard: {
      username: process.env.FLOWABLE_YARD_USERNAME ?? 'devops-warehouse',
      password: process.env.FLOWABLE_YARD_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_YARD_URL ?? 'https://dev.flowable.ovsuite.com/flowable-task',
    },
    execution: {
      username: process.env.FLOWABLE_EXECUTION_USERNAME ?? 'devops-warehouse',
      password: process.env.FLOWABLE_EXECUTION_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_EXECUTION_URL ?? 'https://dev.flowable.ovsuite.com/flowable-task',
    }
  },
  url: {
    idm: process.env.IDM_URL ?? 'localhost',
    admin: process.env.ADMIN_URL ?? 'localhost',
    warehouse: process.env.WAREHOUSE_URL ?? 'localhost',
    yard: process.env.YARD_URL ?? 'localhost',
    execution: process.env.EXECUTION_URL ?? 'localhost',
    ceramicPortal: process.env.CERAMIC_PORTAL_URL ?? 'localhost',
  },
  cognito: {
    region: process.env.COGNITO_REGION ?? `eu-central-1`,
    userPoolId: process.env.COGNITO_USER_POOL_ID ?? `eu-central-1_lH5BYgGC6`,
    accessKey: process.env.COGNITO_ACCESS_KEY_ID ?? `AKIA25V5LREB2UOALDPO`,
    secretAccessKey: process.env.COGNITO_SECRET_ACCESS_KEY ?? `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`,
    temporaryPassword: process.env.TEMPORARY_PASSWORD ?? 'Password123',
  },
  other: {
    awsLambdaTokenSecretKey:
      process.env.LAMBDA_TOKEN_SECRET_KEY ?? 'the!super-classified-secret3#code',
    redis: {
      url: 'redis://localhost:6379'
    },
    awsCeramicDBSyncApi:
      process.env.CERAMIC_DBSYNC_SERVICE_API ??
      `https://nee7xsebhb.execute-api.af-south-1.amazonaws.com/default/sync`,
  }
}
