<div class="col-md-12">
  <small class="text-danger" *ngIf="showTime && (!time || !date)">Please Set a Time and Date</small>
  <div class="row">
    <div [ngClass]="showTime ? 'pr-0 col-7' : 'col-12'">
      <mat-form-field>
        <input matInput
               [(ngModel)]="date"
               (ngModelChange)="valueChange()"
               [matDatepicker]="datePicker"
               [disabled]="disabled"
               [ngModelOptions]="{standalone: true}"
               required>
        <mat-datepicker-toggle
          matSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-4 pl-1 pr-0" *ngIf="showTime">
      <mat-form-field>
        <input matInput
               name="dueDateTime"
               [disabled]="disabled"
               type="time"
               [(ngModel)]="time"
               [value]="time"
               (ngModelChange)="valueChange()"
               [ngModelOptions]="{standalone: true}"
               required>
      </mat-form-field>
      <div class="date-check-mark" *ngIf="showTime">
        <i class="fa fa-check text-success"
           *ngIf="!!time && !!date"></i>
      </div>
    </div>
  </div>
</div>
