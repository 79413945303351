import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { WorkflowProcessService } from '@ov-suite/services-angular';
import { WorkflowProcessRoutes } from './workflow-process.routing';
import { NgbAlertModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowProcessListComponent } from './list/workflow-process-list.component';
import { ProcessViewComponent } from './process-view/process-view.component';
import { ProcessTaskListComponent } from './process-view/process-task-list/process-task-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(WorkflowProcessRoutes),
    FormsModule,
    UiModule,
    NgbAlertModule,
    NgbTabsetModule
  ],
  declarations: [
    WorkflowProcessListComponent,
    ProcessViewComponent,
    ProcessTaskListComponent
  ],
  providers: [WorkflowProcessService]
})
export class WorkflowProcessModule {}
