import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { MobileEod } from '@ov-suite/adminlink-models';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: MobileEod,
      api: 'shared',
      createTitle: 'Mobile End of Day Configuration',
      route: ['mobile-eod'],
      tableTitle: 'Mobile End of Day Configurations'
    })
  ]
})
export class MobileEodModule {}
