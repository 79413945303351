<nav
  #navbar
  class="navbar navbar-expand-lg custom-navbar"
  [ngClass]="{
    'bg-white': open === true,
    'navbar-transparent': open === false
  }"
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-icon btn-round bg-transparent"
          (click)="navigateBack()"
        >
          <i
            aria-hidden="true"
            class="fa fa-arrow-left fa-2x text-center visible-on-sidebar-mini"
          ></i>
          <i
            aria-hidden="true"
            class="fa fa-arrow-left fa-2x text-center visible-on-sidebar-regular"
          ></i>
        </button>
      </div>
      <div class="navbar-toggle" (click)="sidebarToggle()">
        <button type="button" class="navbar-toggler">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a *ngIf="listTitles" class="navbar-brand" href="javascript:void(0)">{{ getTitle() }}</a>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="open = !open"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
<!--      <form>-->
<!--        <div class="input-group no-border navbar-search">-->
<!--          <input-->
<!--            type="text"-->
<!--            value=""-->
<!--            class="form-control"-->
<!--            placeholder="Search..."-->
<!--            disabled-->
<!--          />-->
<!--          <div class="input-group-append">-->
<!--            <div class="input-group-text">-->
<!--              <i aria-hidden="true" class="fa fa-search"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
      <ul class="navbar-nav">
        <li
          class="nav-item btn-rotate dropdown"
          [ngClass]="{ show: toggleApps }"
          (click)="toggleApps = !toggleApps"
        >
          <a
            class="nav-link btn-magnify cursor-pointer dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i aria-hidden="true" class="fa fa-link"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuLink"
          >
            <div *ngFor="let app of navbarApps">
              <button class="dropdown-item" *ngIf="app.hasPermission" (click)="navigate(app.url)">
                {{app.title}}
              </button>
            </div>
            <button class="dropdown-item" *ngIf="navbarApps.length === 0">
              No Apps Available...
            </button>
          </div>
        </li>
        <li
          class="nav-item btn-rotate dropdown"
          [ngClass]="{ show: toggleDomains }"
          (click)="toggleDomains = !toggleDomains"
        >
          <a
            class="nav-link btn-magnify cursor-pointer dropdown-toggle"
            role="button"
            id="dropdownMenuDomain"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i aria-hidden="true" class="fa fa-globe"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuDomain"
          >
            <div *ngFor="let domain of domains">
              <button
                class="dropdown-item"
                [ngClass]="{'dropdown-item-selected': selectedDomainId === domain.id}"
                (click)="setDomain(domain.id)"
              >
                {{domain.name}}
              </button>
            </div>
            <button class="dropdown-item" *ngIf="domains.length === 0">
              No Domains Available...
            </button>
          </div>
        </li>
<!--        <li class="nav-item">-->
<!--          <a class="nav-link btn-rotate" href="javascript:void(0)">-->
<!--            <i aria-hidden="true" class="nc-icon nc-bell-55"></i>-->
<!--            <p>-->
<!--              <span class="d-lg-none d-md-block">Account</span>-->
<!--            </p>-->
<!--          </a>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</nav>
